import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Datenschutz from "../views/Datenschutz.vue";
import Support from "../views/Support.vue";
import Nutzungsbedingungen from "../views/Nutzungsbedingungen.vue";

import ZugangscodeErfolg from "../views/ZugangscodeErfolg.vue";

import AboAdmin from "../views/AboAdmin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/datenschutz/",
    component: Datenschutz,
  },
  {
    path: "/support/",
    component: Support,
  },
  {
    path: "/nutzungsbedingungen/",
    component: Nutzungsbedingungen,
  },
  {
    path: "/abo-verwalten/",
    component: AboAdmin,
  },
  {
    path: "/zugangscode/erfolg",
    component: ZugangscodeErfolg,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
