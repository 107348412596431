<template>
  <b-container class="pb-5">
    <b-container class="fixed-top bg-white">
      <b-row class="pb-0">
        <b-col
          cols="12"
          sm="12"
          md="10"
          offset-md="1"
          lg="8"
          offset-lg="2"
          class="px-2"
        >
          <b-container
            v-show="
              (!errorCode &&
                !errorCodeMore &&
                !hasResults &&
                !hasImageResults &&
                !hasVideoResults &&
                !isLoading &&
                !isLoadingImages &&
                !isLoadingVideos) ||
              hasMaintenance
            "
            class="mt-5 pt-3 pb-3 text-center"
          >
            <b-img :src="require('@/assets/img/logo.png')" id="logo"></b-img>
          </b-container>

          <b-row
            v-if="coupon.code && !coupon.error && !hasMaintenance"
            class="mt-4 mb-2 px-3"
          >
            <b-input-group>
              <b-col class="px-0">
                <b-form-input
                  name="search_form"
                  ref="search_form"
                  maxlength="2048"
                  type="text"
                  v-model="query"
                  v-on:keyup.enter="enterKeyUp"
                  :disabled="coupon.credits == 0"
                  :placeholder="
                    coupon.credits > 0
                      ? 'Anonym googeln:'
                      : 'Bitte Guthaben auffüllen...'
                  "
                  autofocus
                  autocomplete="off"
                  autocapitalize="off"
                  spellcheck="false"
                  autocorrect="off"
                  size="lg"
                  class="rounded-pill text-body"
                  style="padding-left: 18px; padding-right: 36px"
                >
                </b-form-input>
              </b-col>

              <b-input-group-append v-if="coupon.credits > 0">
                <font-awesome-icon
                  v-if="!isLoading && !isLoadingImages && !isLoadingVideos"
                  @click="clearForm"
                  icon="times"
                  size="lg"
                  class="clear-button text-muted"
                />
              </b-input-group-append>

              <b-col v-if="coupon.credits > 0" sm="auto" xs="12" class="px-0">
                <b-row>
                  <b-col class="pr-0">
                    <b-button
                      @click="cancelOrSendRequest"
                      ref="search_button"
                      type="submit"
                      size="lg"
                      class="btn-block shadow-none mt-2 mt-sm-0 ml-sm-2 px-4 btn-dark"
                      style="border-radius: 25px 0 0 25px"
                    >
                      <font-awesome-icon
                        v-if="isLoading"
                        icon="times"
                        style="width: 20px"
                      />
                      <font-awesome-icon
                        v-else
                        icon="search"
                        style="width: 20px"
                      />
                    </b-button>
                  </b-col>
                  <b-col class="px-0">
                    <b-button
                      @click="cancelOrSendRequestImage"
                      ref="image_button"
                      type="submit"
                      size="lg"
                      class="btn-block shadow-none mt-2 mt-sm-0 px-3 btn-dark"
                      style="border-radius: 0; border-left-color: dimgray"
                    >
                      <font-awesome-icon
                        v-if="isLoadingImages"
                        icon="times"
                        style="width: 20px"
                      />
                      <font-awesome-icon
                        v-else
                        :icon="['far', 'image']"
                        style="width: 20px"
                      />
                    </b-button>
                  </b-col>
                  <b-col class="pl-0">
                    <b-button
                      @click="cancelOrSendRequestVideo"
                      ref="video_button"
                      type="submit"
                      size="lg"
                      class="btn-block shadow-none mt-2 mt-sm-0 px-4 btn-dark"
                      style="
                        border-radius: 0 25px 25px 0;
                        border-left-color: dimgray;
                      "
                    >
                      <font-awesome-icon
                        v-if="isLoadingVideos"
                        icon="times"
                        style="width: 20px"
                      />
                      <font-awesome-icon
                        v-else
                        icon="play"
                        style="width: 20px"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-input-group>
          </b-row>

          <div v-else-if="!hasMaintenance">
            <b-input-group class="mt-4 mb-3">
              <b-col class="px-0">
                <b-form-input
                  ref="coupon_form"
                  v-model="coupon.form"
                  v-validate="'required|alpha_num|length:32'"
                  name="access_code"
                  v-on:keyup.enter="verifyCoupon"
                  autofocus
                  autocomplete="off"
                  placeholder="Ihr Zugangscode:"
                  size="lg"
                  class="pl-4 rounded-pill text-body"
                ></b-form-input>
              </b-col>

              <b-col sm="auto" xs="12" class="px-0">
                <b-button
                  v-if="!errors.first('access_code')"
                  @click="verifyCoupon"
                  ref="search_button"
                  type="submit"
                  size="lg"
                  class="btn-block mt-2 mt-sm-0 ml-sm-2 px-4 shadow-none rounded-pill btn-dark"
                >
                  <font-awesome-icon icon="check" />
                </b-button>
              </b-col>
            </b-input-group>

            <p
              v-if="coupon.form.length > 0 && errors.first('access_code')"
              class="text-center red"
            >
              <font-awesome-icon
                icon="exclamation-triangle"
                class="mr-2"
              />Bitte gültigen Code eingeben
            </p>
          </div>

          <div v-else class="text-center">
            <p class="mt-5 mb-3">
              <font-awesome-icon icon="wrench" size="lg" />
            </p>
            <p>
              An Trooia werden Wartungsarbeiten vorgenommen, bitte kommen Sie in
              ein paar Minuten wieder
            </p>
            <p>
              <b-button
                @click="reloadPage"
                size="sm"
                class="mt-3 px-3 rounded-pill btn-dark"
              >
                <font-awesome-icon icon="redo" class="mr-2" />Neu laden
              </b-button>
            </p>
          </div>

          <p
            v-if="
              coupon.code &&
              !coupon.error &&
              coupon.credits > 0 &&
              !hasMaintenance
            "
            class="mb-0 credits text-center text-secondary"
          >
            <span v-if="coupon.credits > 50 && !showCredits"
              ><font-awesome-icon icon="eye" class="mr-1" />
              <b-link @click="showCredits = true" class="text-secondary"
                >Guthaben anzeigen</b-link
              >
            </span>

            <span v-else>
              <span :class="[coupon.credits < 50 ? 'red' : 'text-secondary']">
                <font-awesome-icon icon="search" class="mr-1" />{{
                  formatCredits(coupon.credits)
                }}
                Suchanfragen
              </span>

              <b-link
                v-if="coupon.subscribed && !coupon.subscription_canceled"
                :href="'/abo-verwalten/?code=' + coupon.code"
                class="text-secondary"
              >
                <font-awesome-icon icon="wrench" class="ml-3 mr-1" />Abo
                verwalten
              </b-link>
            </span>

            <span class="ml-5 text-nowrap">
              <font-awesome-icon :icon="['far', 'clock']" class="mr-1" />
              <v-multiselect
                v-model="timeframe"
                :options="timeframes"
                label="lang"
                track-by="id"
                :searchable="false"
                :hideSelected="true"
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                class="timeframes text-secondary"
              >
              </v-multiselect>

              <span class="d-inline-block ml-0">
                <font-awesome-icon icon="list-ul" class="mr-1" />
                <v-multiselect
                  v-model="language"
                  :options="languages"
                  label="lang"
                  track-by="id"
                  :searchable="false"
                  :hideSelected="true"
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                  class="languages text-secondary"
                >
                </v-multiselect>
              </span>
            </span>
          </p>
          <p
            v-else-if="
              coupon.code &&
              !coupon.error &&
              coupon.credits == 0 &&
              !hasMaintenance
            "
            class="credits text-center text-secondary"
          >
            <font-awesome-icon icon="search" class="mr-1" />Kein Guthaben
          </p>
          <div
            v-else-if="
              !errors.first('access_code') && coupon.error && !hasMaintenance
            "
            class="text-center"
          >
            <p>
              <font-awesome-icon
                icon="exclamation-triangle"
                class="mr-2"
              />Zugangscode ungültig oder keine Internetverbindung
            </p>
          </div>

          <div
            v-if="
              (isLoading || isLoadingImages || isLoadingVideos) && !errorCode
            "
            class="mt-5 text-secondary text-center"
          >
            <h1>
              <font-awesome-icon icon="spinner" class="fa-spin" />
            </h1>

            <transition name="fade">
              <p v-if="loadingMsg.show" class="loadingMessage">
                <span v-if="loadingMsg.index == 0"
                  ><span v-if="isLoadingVideos">Youtube</span
                  ><span v-else>Google</span> kontaktieren</span
                >
                <span v-else>Ergebnisse empfangen</span>
              </p>
            </transition>
          </div>

          <b-container
            v-else-if="errorCode && !hasMaintenance"
            class="mt-5 pt-3 text-center hyphened"
          >
            <b-row>
              <b-col></b-col>

              <b-col cols="12" md="10">
                <p class="mb-3">
                  <font-awesome-icon
                    icon="exclamation-triangle"
                    size="lg"
                    class="text-warning"
                  />
                </p>

                <div v-if="errorCode == 'ERR_02_ECONNABORTED'">
                  <p>
                    Anfrage konnte nicht verarbeitet werden, bitte wiederholen:
                  </p>
                  <b-button
                    @click="cancelOrSendRequest"
                    size="sm"
                    variant="outline-dark"
                  >
                    Wiederholen
                  </b-button>
                </div>

                <div v-else-if="errorCode == 'ERR_12_ECONNABORTED'">
                  <p>
                    Anfrage konnte nicht verarbeitet werden, bitte wiederholen:
                  </p>
                  <b-button
                    @click="cancelOrSendRequestVideo"
                    size="sm"
                    variant="outline-dark"
                  >
                    Wiederholen
                  </b-button>
                </div>

                <div v-else-if="errorCode == 'ERR_22_ECONNABORTED'">
                  <p>
                    Anfrage konnte nicht verarbeitet werden, bitte wiederholen:
                  </p>
                  <b-button
                    @click="cancelOrSendRequestImage"
                    size="sm"
                    variant="outline-dark"
                  >
                    Wiederholen
                  </b-button>
                </div>

                <div
                  v-else-if="
                    errorCode == 'ERR_02_NETWORKERROR' ||
                    errorCode == 'ERR_05_NETWORKERROR'
                  "
                >
                  <p>
                    Es konnte keine Verbindung zum Internet hergestellt werden
                  </p>
                  <b-button
                    @click="cancelOrSendRequest"
                    size="sm"
                    variant="outline-dark"
                  >
                    Wiederholen
                  </b-button>
                </div>

                <p
                  v-else-if="
                    !hasRestriction &&
                    (errorCode == 'RES_01' ||
                      errorCode == 'RES_11' ||
                      errorCode == 'RES_21')
                  "
                >
                  Google hat für diesen Suchbegriff keine Ergebnisse geliefert
                </p>

                <p v-else-if="hasRestriction">
                  Ihr Zugangscode ist für diese IP-Adresse nicht gültig
                </p>

                <div v-else-if="errorCode == 'ERR_01_404'">
                  <p>
                    Die Suchanfrage konnte nicht verarbeitet werden, bitte
                    entschuldigen Sie. Der Vorfall wurde gemeldet und wird
                    untersucht.
                  </p>
                </div>

                <div v-else>
                  <p>
                    Ein Fehler ist aufgetreten, bitte mit Klick auf den Link an
                    Trooia melden:
                  </p>
                  <p>
                    <b-link
                      :href="
                        'mailto:info@trooia.app?subject=Fehlercode%20' +
                        errorCode +
                        '%20aufgetreten%20(' +
                        coupon.code +
                        ')'
                      "
                      class="red"
                      ><font-awesome-icon
                        icon="envelope"
                        class="mr-1"
                      />info@trooia.app</b-link
                    >
                  </p>
                  <p class="mt-5 text-secondary font-italic">
                    Fehlercode: {{ errorCode }}
                  </p>
                </div>
              </b-col>

              <b-col></b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="my-5 pt-5 px-0">
      <b-row class="mt-5">
        <b-col
          cols="12"
          sm="12"
          md="10"
          offset-md="1"
          lg="8"
          offset-lg="2"
          class="mt-4"
        >
          <b-container
            v-if="hasResults && !hasMaintenance"
            class="mt-5 mt-sm-0 px-0"
          >
            <b-row
              v-for="(result, index) in results.places"
              :key="'place-' + index"
            >
              <b-col
                v-if="index == 0 || !isCollapsed.places"
                cols="1"
                class="px-0 pt-1"
              >
                <a
                  v-if="result.link"
                  :href="result.link"
                  rel="noreferrer"
                  target="_blank"
                  class="text-dark"
                  ><font-awesome-icon
                    :icon="['fas', 'map-marker-alt']"
                    class="mt-1"
                /></a>
                <font-awesome-icon
                  v-else-if="index == 0 && !result.link"
                  :icon="['fas', 'map-marker-alt']"
                  class="mt-1"
                />
              </b-col>
              <b-col v-if="index == 0 || !isCollapsed.places" class="px-0">
                <p v-if="result.link" class="mb-0">
                  <a
                    :href="result.link"
                    class="place"
                    rel="noreferrer"
                    target="_blank"
                    >{{ result.title }}</a
                  >
                </p>
                <p v-else class="place-nolink mb-0">{{ result.title }}</p>

                <div v-if="result.phone">
                  <p class="mb-0 text-dark">
                    <em>{{ result.address }}</em>
                  </p>
                  <p class="text-dark">
                    <em
                      ><a
                        :href="'tel:' + result.phone"
                        class="phone"
                        rel="noreferrer"
                        ><font-awesome-icon
                          :icon="['fas', 'phone']"
                          class="mr-2"
                        />{{ result.phone }}</a
                      ></em
                    >
                  </p>
                </div>
                <div v-else>
                  <p class="text-dark">
                    <em>{{ result.address }}</em>
                  </p>
                </div>
              </b-col>
            </b-row>

            <b-row v-if="results.places.length > 1" class="mb-4 text-center">
              <b-col>
                <b-button
                  @click="isCollapsed.places = !isCollapsed.places"
                  size="sm"
                  variant="outline-dark shadow-none"
                >
                  <span v-if="isCollapsed.places">Mehr Orte</span>
                  <span v-else>Weniger Orte</span>
                </b-button>
              </b-col>
            </b-row>

            <b-row
              v-for="(result, index) in results.videos"
              :key="'video-' + index"
            >
              <b-col
                v-if="index == 0 || !isCollapsed.videos"
                cols="1"
                class="px-0 pt-1"
              >
                <a
                  :href="result.link"
                  rel="noreferrer"
                  target="_blank"
                  class="red"
                  ><font-awesome-icon
                    :icon="['far', 'play-circle']"
                    class="mt-1"
                /></a>
              </b-col>
              <b-col v-if="index == 0 || !isCollapsed.videos" class="px-0">
                <p class="mb-0">
                  <a
                    :href="result.link"
                    class="video"
                    rel="noreferrer"
                    target="_blank"
                    >{{ result.title
                    }}<span v-if="result.duration">
                      [{{ result.duration }}]</span
                    ></a
                  >
                </p>
                <p class="text-dark">
                  <em
                    >{{ result.platform }}{{ getVideoChannel(result)
                    }}<span v-if="result.date">, {{ result.date }}</span></em
                  >
                </p>
              </b-col>
            </b-row>

            <b-row v-if="results.videos.length > 1" class="mb-4 text-center">
              <b-col>
                <b-button
                  @click="isCollapsed.videos = !isCollapsed.videos"
                  size="sm"
                  variant="outline-dark shadow-none"
                >
                  <span v-if="isCollapsed.videos">Mehr Videos</span>
                  <span v-else>Weniger Videos</span>
                </b-button>
              </b-col>
            </b-row>

            <b-row
              v-for="(result, index) in results.stories"
              :key="'stories-' + index"
            >
              <b-col
                v-if="index == 0 || !isCollapsed.stories"
                cols="1"
                class="px-0 pt-1"
              >
                <a
                  :href="result.link"
                  rel="noreferrer"
                  target="_blank"
                  class="text-dark"
                  ><font-awesome-icon
                    :icon="['far', 'newspaper']"
                    class="text-black"
                /></a>
              </b-col>
              <b-col v-if="index == 0 || !isCollapsed.stories" class="px-0">
                <p class="mb-0 hyphened">
                  <a
                    :href="result.link"
                    class="story"
                    rel="noreferrer"
                    target="_blank"
                    >{{ result.title }}</a
                  >
                </p>
                <p class="text-dark">
                  <em
                    >{{ getHostname(result.link)
                    }}<span v-if="result.date">, {{ result.date }}</span></em
                  >
                </p>
              </b-col>
            </b-row>

            <b-row v-if="results.stories.length > 1" class="mb-4 text-center">
              <b-col>
                <b-button
                  @click="isCollapsed.stories = !isCollapsed.stories"
                  size="sm"
                  variant="outline-dark shadow-none"
                >
                  <span v-if="isCollapsed.stories">Mehr Schlagzeilen</span>
                  <span v-else>Weniger Schlagzeilen</span>
                </b-button>
              </b-col>
            </b-row>

            <b-row
              v-for="(result, index) in results.organic"
              :key="'organic-' + index"
            >
              <b-col cols="1" class="px-0 pt-1">
                <a
                  v-if="!result.divider && isPdf(result.link)"
                  :href="result.link"
                  rel="noreferrer"
                  target="_blank"
                  class="text-dark"
                  ><font-awesome-icon
                    :icon="['far', 'file-pdf']"
                    class="mt-1 red"
                /></a>
                <a
                  v-else-if="!result.divider"
                  :href="result.link"
                  rel="noreferrer"
                  target="_blank"
                  class="text-dark"
                >
                  <font-awesome-icon
                    v-if="result.link.includes('wikipedia.org')"
                    :icon="['fab', 'wikipedia-w']"
                    class="mt-1"
                  />
                  <font-awesome-icon
                    v-else-if="result.link.includes('youtube.com')"
                    :icon="['far', 'play-circle']"
                    class="mt-1 red"
                  />
                  <font-awesome-icon v-else icon="globe" class="mt-1" />
                </a>
              </b-col>
              <b-col v-if="!result.divider" class="px-0">
                <p class="mb-0">
                  <a
                    :href="result.link"
                    class="organic"
                    rel="noreferrer"
                    target="_blank"
                    >{{ result.title }}</a
                  >
                </p>
                <p class="mb-1 text-black hyphened">{{ result.snippet }}</p>
                <p class="text-dark hyphened">
                  <em>{{
                    result.displayed_link.replace(/https:\/\/|http:\/\//, "")
                  }}</em>
                </p>
              </b-col>
              <b-col v-else class="px-0">
                <p class="text-center">
                  <font-awesome-icon icon="chevron-down" class="red" />
                </p>
              </b-col>
            </b-row>

            <b-row class="mt-2 mb-5">
              <b-col cols="1"></b-col>

              <b-col class="text-center">
                <b-button
                  ref="more_button"
                  @click="cancelOrSendRequestMore"
                  :disabled="coupon.credits == 0"
                  class="px-4 rounded-pill btn-dark shadow-none"
                >
                  <span v-if="isLoadingMore"
                    ><font-awesome-icon
                      icon="spinner"
                      class="mr-2 fa-spin"
                    />Abbrechen</span
                  >
                  <span
                    v-else-if="
                      !isLoadingMore && !errorCodeMore && coupon.credits > 0
                    "
                    ><font-awesome-icon
                      icon="search"
                      size="sm"
                      class="mr-2"
                    />Mehr Ergebnisse</span
                  >
                  <span v-else-if="coupon.credits == 0"
                    ><font-awesome-icon
                      icon="exclamation-triangle"
                      size="sm"
                      class="mr-2"
                    />Kein Guthaben</span
                  >
                  <span v-else
                    ><font-awesome-icon
                      icon="redo"
                      class="mr-2"
                    />Wiederholen</span
                  >
                </b-button>

                <p v-if="errorCodeMore" class="mt-4 mb-1">
                  <font-awesome-icon
                    icon="exclamation-triangle"
                    class="text-warning"
                  />
                </p>
                <p v-if="errorCodeMore == 'ERR_05_ECONNABORTED'">
                  Anfrage konnte nicht verarbeitet werden, bitte wiederholen
                </p>
                <p v-else-if="errorCodeMore == 'ERR_05_NETWORKERROR'">
                  Es konnte keine Verbindung zum Internet hergestellt werden
                </p>
                <p v-else-if="errorCodeMore == 'RES_03_1'">
                  Google hat für diesen Suchbegriff keine Ergebnisse gefunden
                </p>
                <div v-else-if="errorCodeMore">
                  <p>
                    Ein Fehler ist aufgetreten, bitte mit Klick auf den Link an
                    Trooia melden:
                  </p>
                  <p>
                    <b-link
                      :href="
                        'mailto:info@trooia.app?subject=Fehlercode%20' +
                        errorCodeMore +
                        '%20aufgetreten%20(' +
                        coupon.code +
                        ')'
                      "
                      class="red"
                      ><font-awesome-icon
                        icon="envelope"
                        class="mr-1"
                      />info@trooia.app</b-link
                    >
                  </p>
                </div>
              </b-col>
            </b-row>

            <b-row v-if="results.questions.length > 0" class="mb-5">
              <b-container class="px-0">
                <p class="mb-2 text-black">Antworten auf ähnliche Fragen:</p>
                <b-row
                  v-for="(result, index) in results.questions"
                  :key="'questions-' + index"
                >
                  <b-col v-if="result.displayed_link" cols="1" class="pt-1">
                    <font-awesome-icon icon="globe" class="mt-1 text-black" />
                  </b-col>
                  <b-col v-if="result.displayed_link">
                    <p class="mb-0">
                      <a
                        :href="result.link"
                        class="question"
                        rel="noreferrer"
                        target="_blank"
                        >{{ result.question }}</a
                      >
                    </p>
                    <p class="mb-2 text-dark hyphened">
                      {{
                        result.displayed_link.replace(
                          /https:\/\/|http:\/\//,
                          ""
                        )
                      }}
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-row>

            <b-row v-if="results.searches.length > 0" class="mb-5">
              <b-container class="px-0">
                <p class="mb-2 text-black">Verwandte Suchanfragen:</p>
                <b-row
                  v-for="(result, index) in results.searches"
                  :key="'searches-' + index"
                >
                  <b-col cols="1" class="pt-1">
                    <font-awesome-icon icon="search" class="mt-1 text-black" />
                  </b-col>
                  <b-col>
                    <p
                      @click="sendSearchRequestFirst(result.query)"
                      class="mb-1 related"
                    >
                      {{ result.query }}
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-row>
          </b-container>

          <b-container
            v-else-if="hasVideoResults && !hasMaintenance"
            class="mt-5 mt-sm-0 px-0"
          >
            <b-row
              v-for="(result, index) in resultsYoutube.videos"
              :key="'video-' + index"
              class="pb-3 text-center text-sm-left"
            >
              <b-col cols="12" sm="3" class="pl-0 pr-0 pr-sm-2 pt-1">
                <a :href="result.link" rel="noreferrer" target="_blank">
                  <b-img-lazy
                    :src="result.thumbnail"
                    rounded
                    fluid
                  ></b-img-lazy>
                </a>
              </b-col>
              <b-col class="px-0">
                <p class="mb-0">
                  <a
                    :href="result.link"
                    class="video"
                    rel="noreferrer"
                    target="_blank"
                    >{{ result.title
                    }}<span v-if="result.length">
                      [{{ result.length }}]</span
                    ></a
                  >
                </p>
                <p class="text-dark">
                  <em
                    >{{ result.channel.name
                    }}<span v-if="result.published"
                      >, {{ result.published }}</span
                    ></em
                  >
                </p>
              </b-col>
            </b-row>

            <b-row
              v-if="resultsYoutube.similar.length > 0"
              class="mt-3 mt-sm-5 mb-0 text-dark"
            >
              <p class="hyphened">
                Beliebte Videos zu "<strong>{{ query }}</strong
                >":
              </p>
            </b-row>

            <b-row
              v-for="(result, index) in resultsYoutube.similar"
              :key="'similar-' + index"
              class="pb-3 text-center text-sm-left"
            >
              <b-col cols="12" sm="3" class="pl-0 pr-0 pr-sm-2 pt-1">
                <a :href="result.link" rel="noreferrer" target="_blank">
                  <b-img-lazy
                    :src="result.thumbnail"
                    rounded
                    fluid
                  ></b-img-lazy>
                </a>
              </b-col>
              <b-col class="px-0">
                <p class="mb-0">
                  <a
                    :href="result.link"
                    class="video"
                    rel="noreferrer"
                    target="_blank"
                    >{{ result.title
                    }}<span v-if="result.length">
                      [{{ result.length }}]</span
                    ></a
                  >
                </p>
                <p class="text-dark">
                  <em
                    >{{ result.channel.name
                    }}<span v-if="result.published"
                      >, {{ result.published }}</span
                    ></em
                  >
                </p>
              </b-col>
            </b-row>
          </b-container>

          <b-container
            v-else-if="hasImageResults && !hasMaintenance"
            class="mt-5 mt-sm-0 px-0"
          >
            <b-row
              v-for="(result, index) in results.images"
              :key="'image-' + index"
              class="pb-3 text-center text-sm-left"
            >
              <b-col
                v-if="results.images[index].show"
                cols="12"
                sm="3"
                class="pl-0 pr-0 pr-sm-2 pt-1"
              >
                <a :href="result.original" rel="noreferrer" target="_blank">
                  <b-img-lazy
                    :src="result.original"
                    rounded
                    fluid
                    @error.native="results.images[index].show = false"
                  ></b-img-lazy>
                </a>
              </b-col>
              <b-col v-if="results.images[index].show" class="px-0">
                <p class="mb-0">
                  <a
                    :href="result.link"
                    class="video"
                    rel="noreferrer"
                    target="_blank"
                    >{{ result.title }}</a
                  >
                </p>
                <p class="text-dark">
                  <em>{{ result.source }}</em>
                </p>
              </b-col>
            </b-row>
          </b-container>

          <b-container
            v-else-if="
              !errorCode &&
              !isLoading &&
              !isLoadingMore &&
              !isLoadingImages &&
              !isLoadingVideos &&
              !hasMaintenance
            "
            class="px-0 text-center"
            style="margin-top: 150px"
          >
            <b-row>
              <b-col></b-col>

              <b-col v-if="coupon.code == null" cols="12" md="10" class="px-0">
                <p class="mt-5 pt-3 mb-2">
                  <b-img
                    :src="require('@/assets/img/horse.png')"
                    id="horse"
                  ></b-img>
                </p>
                <p class="mb-2 font-weight-bold">Anonym googeln</p>
                <p class="hyphened">
                  Suchergebnisse direkt von Google, aber
                  <em>anonym</em> und <em>ohne Werbung</em>. Es entstehen keine
                  Persönlichkeitsprofile von Ihnen in den Datenbanken von
                  Google.
                </p>

                <p class="mt-4 mb-2 font-weight-bold">
                  <font-awesome-icon
                    :icon="['far', 'newspaper']"
                    class="mr-2"
                  />In der Presse
                </p>
                <p class="mb-4">
                  <b-link
                    href="https://cronimund.ch/var/20220718_vybech.pdf"
                    target="_blank"
                    class="red"
                    >Vybe Testbericht<font-awesome-icon
                      icon="external-link-alt"
                      size="xs"
                      class="mr-2 ml-1"
                      style="margin-bottom: 1px"
                  /></b-link>
                  <b-link
                    href="https://cronimund.ch/var/20210206_trooia_nzz.pdf"
                    target="_blank"
                    class="ml-2 red"
                    >Neue Zürcher Zeitung<font-awesome-icon
                      icon="external-link-alt"
                      size="xs"
                      class="mr-2 ml-1"
                      style="margin-bottom: 1px"
                  /></b-link>
                  <b-link
                    href="https://cronimund.ch/var/20210324_trooia_sueddeutsche.pdf"
                    target="_blank"
                    class="ml-2 red"
                    >Süddeutsche Zeitung<font-awesome-icon
                      icon="external-link-alt"
                      size="xs"
                      class="mr-2 ml-1"
                      style="margin-bottom: 1px"
                  /></b-link>
                </p>

                <p class="mt-4 mb-2 font-weight-bold">
                  <font-awesome-icon icon="shield-alt" class="mr-2" />Ihre
                  Suchanfragen, Ihre Privatsphäre
                </p>
                <p class="mb-4 hyphened">
                  Für Google ist Ihre Privatsphäre dank Ihres Suchverlaufs aus
                  Glas. Trooia verschleiert Ihre Suchaktivität vor Google und
                  sammelt keine Benutzer-Daten.
                </p>

                <p class="mt-4 mb-2 font-weight-bold">
                  <font-awesome-icon icon="dollar-sign" class="mr-2" />Kosten
                </p>
                <p class="mb-4 hyphened">
                  Trooia finanziert sich nicht durch Werbung und bezahlt die
                  Betriebskosten mit Hilfe kleiner Benutzergebühren.
                  Suchanfragenpakete sind ab €5 erhältlich.
                </p>
              </b-col>

              <b-col v-else cols="12" md="10" class="px-0">
                <p class="mt-5 pt-3 mb-3">
                  <b-img
                    :src="require('@/assets/img/horse.png')"
                    id="horse"
                  ></b-img>
                </p>
                <p class="hyphened">
                  Trooia wird den Betrieb aufgrund zu kleinen Nutzerzahlen
                  leider
                  <strong class="red">per Ende Januar 2025</strong> einstellen.
                  Bestehende Suchanfragen-Guthaben werden danach verfallen. Ich
                  hoffe auf Ihr Verständnis.
                </p>
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
  const API_SEARCH_ENDPOINT = "/search";
  const API_IMAGE_ENDPOINT = "/image";
  const API_VIDEO_ENDPOINT = "/video";
  const API_COUPON_ENDPOINT = "/coupon";
  const REQ_TIMEOUT = 7000;
  const COUPONS_TIMEOUT = 3000;

  const LANGUAGES = [
    { id: 0, iso: "", lang: "Alle Sprachen" },
    { id: 1, iso: "de", lang: "Deutsch" },
    { id: 2, iso: "en", lang: "English" },
    { id: 3, iso: "es", lang: "Español" },
    { id: 4, iso: "fr", lang: "Français" },
    { id: 5, iso: "it", lang: "Italiano" },
    { id: 9, iso: "nl", lang: "Nederlands" },
    { id: 6, iso: "pl", lang: "Polskie" },
    { id: 7, iso: "pt", lang: "Português" },
    { id: 8, iso: "tr", lang: "Türk" },
  ];

  const TIMEFRAMES = [
    { id: 0, lang: "Beliebige Zeit" },
    { id: 1, lang: "Letzte Woche" },
    { id: 2, lang: "Letzter Monat" },
    { id: 3, lang: "Letztes Jahr" },
  ];

  export default {
    data() {
      return {
        showCredits: false,
        showAccessCode: false,
        coupon: {
          form: "",
          code: null,
          credits: null,
          subscribed: false,
          subscription_canceled: false,
          error: false,
        },
        query: "",
        language: LANGUAGES[0],
        languages: LANGUAGES,
        timeframe: TIMEFRAMES[0],
        timeframes: TIMEFRAMES,
        googleStartParam: 0,
        cancelTokenSource: null,
        lastQueryType: 0,
        results: {
          places: [],
          videos: [],
          stories: [],
          organic: [],
          questions: [],
          searches: [],
          images: [],
        },
        resultsYoutube: {
          videos: [],
          similar: [],
        },
        isCollapsed: {
          places: true,
          videos: true,
          stories: true,
        },
        isLoading: false,
        isLoadingMore: false,
        isLoadingImages: false,
        isLoadingVideos: false,
        loadingMsg: {
          show: false,
          index: 0,
        },
        hasResults: false,
        hasImageResults: false,
        hasVideoResults: false,
        hasMaintenance: false,
        hasRestriction: false,
        errorCode: null,
        errorCodeMore: null,
      };
    },
    mounted() {
      const code = this.$route.query.code;

      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          this.getCoupon(code);
          this.$emit("couponCode", code);
        } else {
          this.$router.push("/");
        }
      } else {
        try {
          if (localStorage.code) {
            this.getCoupon(localStorage.code);
            this.$emit("couponCode", localStorage.code);
          } else {
            this.coupon.code = null;
          }
        } catch (err) {
          this.coupon.code = null;
        }
      }

      try {
        if (localStorage.language) {
          const lang = LANGUAGES.find(
            (lang) => lang.id == localStorage.language
          );

          if (lang) this.language = lang;
        } else {
          this.setBrowserLanguage();
        }
      } catch (err) {
        this.setBrowserLanguage();
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
    updated() {
      try {
        if (localStorage.code && !this.$route.query.code) {
          let url = "/?code=" + localStorage.code;
          if (this.$route.query.q) {
            url = url + "&q=" + this.$route.query.q;
          }

          this.$router.push(url);
        }
      } catch (err) {} // eslint-disable-line
    },
    watch: {
      language: (lang) => {
        try {
          localStorage.language = lang.id;
          // eslint-disable-next-line no-empty
        } catch (err) {}
      },
    },
    methods: {
      enterKeyUp(evt) {
        switch (this.lastQueryType) {
          case 1:
            this.cancelOrSendRequestVideo(evt);
            break;

          case 2:
            this.cancelOrSendRequestImage(evt);
            break;

          default:
            this.cancelOrSendRequest(evt);
        }
      },
      cancelOrSendRequest(evt) {
        this.showCredits = false;
        this.lastQueryType = 0;
        evt.preventDefault();

        this.$refs.search_form.blur();
        this.$refs.search_button.blur();

        if (this.query.length > 1) {
          if (this.isLoading) {
            if (this.cancelTokenSource != null) {
              this.cancelTokenSource.cancel();
            }
          } else {
            this.displayLoadingMsg();
            this.sendSearchRequestFirst(this.query);
          }
        }
      },
      cancelOrSendRequestMore(evt) {
        evt.preventDefault();

        this.$refs.more_button.blur();

        if (this.query.length > 1) {
          if (this.isLoadingMore) {
            if (this.cancelTokenSource != null) {
              this.cancelTokenSource.cancel();
            }
          } else {
            this.sendSearchRequestMore();
          }
        }
      },
      cancelOrSendRequestVideo(evt) {
        this.showCredits = false;
        this.lastQueryType = 1;
        evt.preventDefault();

        this.$refs.search_form.blur();
        this.$refs.video_button.blur();

        if (this.query.length > 1) {
          if (this.isLoadingVideos) {
            if (this.cancelTokenSource != null) {
              this.cancelTokenSource.cancel();
            }
          } else {
            this.displayLoadingMsg();
            this.sendVideoRequest(this.query);
          }
        }
      },
      cancelOrSendRequestImage(evt) {
        this.showCredits = false;
        this.lastQueryType = 2;
        evt.preventDefault();

        this.$refs.search_form.blur();
        this.$refs.image_button.blur();

        if (this.query.length > 1) {
          if (this.isLoadingImages) {
            if (this.cancelTokenSource != null) {
              this.cancelTokenSource.cancel();
            }
          } else {
            this.displayLoadingMsg();
            this.sendImageRequest(this.query);
          }
        }
      },
      sendSearchRequestFirst(query) {
        this.resetResults();

        this.isLoading = true;
        this.errorCode = null;
        this.errorCodeMore = null;

        this.query = query;
        const searchQuery = encodeURIComponent(query);
        this.googleStartParam = 0;

        const requestUrl =
          API_SEARCH_ENDPOINT +
          "?q=" +
          searchQuery +
          "&start=" +
          this.googleStartParam +
          "&l=" +
          this.language.id +
          "&tf=" +
          this.timeframe.id +
          "&code=" +
          this.coupon.code;

        this.cancelTokenSource = this.axios.CancelToken.source();
        this.axios
          .get(requestUrl, {
            timeout: REQ_TIMEOUT,
            cancelToken: this.cancelTokenSource.token,
          })
          .then((response) => {
            if (response.status == 200) {
              this.hasMaintenance = response.data.maintenance_active;
              this.hasRestriction = response.data.ip_restricted;

              if (response.data.data) {
                this.coupon.credits = response.data.coupon.credits;
                const results = response.data.data;

                if (results.places) {
                  if (results.places.length > 0) {
                    this.results.places = results.places;
                    this.hasResults = true;
                  }
                }

                if (results.videos) {
                  if (results.videos.length > 0) {
                    this.results.videos = results.videos;
                    this.hasResults = true;
                  }
                }

                if (results.stories) {
                  if (results.stories.length > 0) {
                    this.results.stories = results.stories;
                    this.hasResults = true;
                  }
                }

                if (results.organic) {
                  if (results.organic.length > 0) {
                    this.results.organic = results.organic;
                    this.hasResults = true;
                  }
                }

                if (results.questions) {
                  if (results.questions.length > 0) {
                    this.results.questions = results.questions;
                    this.hasResults = true;
                  }
                }

                if (results.searches) {
                  if (results.searches.length > 0) {
                    this.results.searches = results.searches;
                    this.hasResults = true;
                  }
                }

                if (!this.hasResults) {
                  this.handleError("RES_01");
                }
              } else {
                this.handleError("RES_01");
              }
            } else {
              this.handleError("RES_02_" + response.status);
            }
          })
          .catch((err) => {
            if (!this.axios.isCancel(err)) {
              if (err.response) {
                this.handleError("ERR_01_" + err.response.status);
              } else if (err.request) {
                if (err.code) {
                  this.handleError("ERR_02_" + err.code);
                } else {
                  this.handleError("ERR_02_" + err.message);
                }
              } else {
                this.handleError("ERR_03_" + err.code);
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.cancelTokenSource = null;
          });
      },
      sendSearchRequestMore() {
        this.isLoadingMore = true;
        this.errorCodeMore = null;
        this.hasRestriction = false;

        const searchQuery = encodeURIComponent(this.query);

        const requestUrl =
          API_SEARCH_ENDPOINT +
          "?q=" +
          searchQuery +
          "&start=" +
          (this.googleStartParam + 20) +
          "&l=" +
          this.language.id +
          "&tf=" +
          this.timeframe.id +
          "&code=" +
          this.coupon.code;

        this.cancelTokenSource = this.axios.CancelToken.source();
        this.axios
          .get(requestUrl, {
            timeout: REQ_TIMEOUT,
            cancelToken: this.cancelTokenSource.token,
          })
          .then((response) => {
            if (response.status == 200) {
              this.hasMaintenance = response.data.maintenance_active;
              this.hasRestriction = response.data.ip_restricted;

              if (response.data.data) {
                const results = response.data.data;

                if (results.organic) {
                  if (results.organic.length > 0) {
                    this.coupon.credits = response.data.coupon.credits;

                    this.googleStartParam = this.googleStartParam + 20;

                    this.results.organic.push({ divider: true });
                    this.results.organic = this.results.organic.concat(
                      results.organic
                    );
                  } else {
                    this.handleErrorMore("RES_03_1");
                  }
                } else {
                  this.handleErrorMore("RES_03_2");
                }
              } else {
                this.handleErrorMore("RES_03_3");
              }
            } else {
              this.handleErrorMore("RES_04_" + response.status);
            }
          })
          .catch((err) => {
            if (!this.axios.isCancel(err)) {
              if (err.response) {
                this.handleErrorMore("ERR_04_" + err.response.status);
              } else if (err.request) {
                if (err.code) {
                  this.handleErrorMore("ERR_05_" + err.code);
                } else {
                  this.handleErrorMore("ERR_05_" + err.message);
                }
              } else {
                this.handleErrorMore("ERR_06_" + err.code);
              }
            }
          })
          .finally(() => {
            this.isLoadingMore = false;
            this.cancelTokenSource = null;
          });
      },
      sendVideoRequest(query) {
        this.resetResults();

        this.isLoadingVideos = true;
        this.errorCode = null;

        this.query = query;
        const searchQuery = encodeURIComponent(query);

        const requestUrl =
          API_VIDEO_ENDPOINT +
          "?q=" +
          searchQuery +
          "&l=" +
          this.language.id +
          "&code=" +
          this.coupon.code;

        this.cancelTokenSource = this.axios.CancelToken.source();
        this.axios
          .get(requestUrl, {
            timeout: REQ_TIMEOUT,
            cancelToken: this.cancelTokenSource.token,
          })
          .then((response) => {
            if (response.status == 200) {
              this.hasMaintenance = response.data.maintenance_active;
              this.hasRestriction = response.data.ip_restricted;

              if (response.data.data) {
                this.coupon.credits = response.data.coupon.credits;
                const results = response.data.data;

                if (results.videos) {
                  if (results.videos.length > 0) {
                    this.resultsYoutube.videos = results.videos;
                    this.hasVideoResults = true;
                  }
                }

                if (results.similar) {
                  if (results.similar.length > 0) {
                    this.resultsYoutube.similar = results.similar;
                  }
                }

                if (!this.hasVideoResults) {
                  this.handleError("RES_11");
                }
              } else {
                this.handleError("RES_11");
              }
            } else {
              this.handleError("RES_12_" + response.status);
            }
          })
          .catch((err) => {
            if (!this.axios.isCancel(err)) {
              if (err.response) {
                this.handleError("ERR_11_" + err.response.status);
              } else if (err.request) {
                if (err.code) {
                  this.handleError("ERR_12_" + err.code);
                } else {
                  this.handleError("ERR_12_" + err.message);
                }
              } else {
                this.handleError("ERR_13_" + err.code);
              }
            }
          })
          .finally(() => {
            this.isLoadingVideos = false;
            this.cancelTokenSource = null;
          });
      },
      sendImageRequest(query) {
        this.resetResults();

        this.isLoadingImages = true;
        this.errorCode = null;

        this.query = query;
        const searchQuery = encodeURIComponent(query);

        const requestUrl =
          API_IMAGE_ENDPOINT +
          "?q=" +
          searchQuery +
          "&l=" +
          this.language.id +
          "&code=" +
          this.coupon.code;

        this.cancelTokenSource = this.axios.CancelToken.source();
        this.axios
          .get(requestUrl, {
            timeout: REQ_TIMEOUT,
            cancelToken: this.cancelTokenSource.token,
          })
          .then((response) => {
            if (response.status == 200) {
              this.hasMaintenance = response.data.maintenance_active;
              this.hasRestriction = response.data.ip_restricted;

              if (response.data.data) {
                this.coupon.credits = response.data.coupon.credits;
                const results = response.data.data;

                if (results.images) {
                  if (results.images.length > 0) {
                    this.results.images = results.images;
                    this.hasImageResults = true;
                  }
                }

                if (!this.hasImageResults) {
                  this.handleError("RES_21");
                }
              } else {
                this.handleError("RES_21");
              }
            } else {
              this.handleError("RES_22_" + response.status);
            }
          })
          .catch((err) => {
            if (!this.axios.isCancel(err)) {
              if (err.response) {
                this.handleError("ERR_21_" + err.response.status);
              } else if (err.request) {
                if (err.code) {
                  this.handleError("ERR_22_" + err.code);
                } else {
                  this.handleError("ERR_22_" + err.message);
                }
              } else {
                this.handleError("ERR_23_" + err.code);
              }
            }
          })
          .finally(() => {
            this.isLoadingImages = false;
            this.cancelTokenSource = null;
          });
      },
      verifyCoupon(evt) {
        evt.preventDefault();
        this.$refs.coupon_form.blur();

        if (this.coupon.form) {
          if (this.coupon.form.match(/^[a-f0-9]{32}$/gm)) {
            this.getCoupon(this.coupon.form);
            this.$emit("couponCode", this.coupon.form);
          } else {
            this.coupon.code = null;
          }
        }
      },
      getCoupon(code) {
        this.axios
          .get(API_COUPON_ENDPOINT + "?code=" + code, {
            timeout: COUPONS_TIMEOUT,
          })
          .then((response) => {
            const res = response.data;

            if (res) {
              this.coupon.code = code;
              this.coupon.credits = res.credits;
              this.coupon.subscribed = res.subscribed;
              this.coupon.subscription_canceled = res.subscription_canceled;

              try {
                localStorage.code = code;
              } catch (err) {} // eslint-disable-line

              this.hasMaintenance = res.maintenance_active;
              this.coupon.error = false;

              if (this.$route.query.q) {
                this.sendSearchRequestFirst(this.$route.query.q);
              }
            } else {
              this.hasMaintenance = false;
              this.coupon.error = true;
            }
          })
          .catch(() => {
            this.hasMaintenance = false;
            this.coupon.error = true;

            try {
              localStorage.removeItem("code");
            } catch (err) {} // eslint-disable-line

            this.$router.push("/");
          });
      },
      displayLoadingMsg() {
        setTimeout(() => {
          this.loadingMsg.show = true;

          setTimeout(() => {
            this.loadingMsg.show = false;
            this.loadingMsg.index++;

            if (this.loadingMsg.index > 1) {
              this.loadingMsg.index = 0;
            } else {
              this.displayLoadingMsg();
            }
          }, 2000);
        }, 1500);
      },
      getHostname(url) {
        return new URL(url).hostname;
      },
      getVideoChannel(video) {
        if (video.channel) {
          return ` (${video.channel})`;
        } else {
          return "";
        }
      },
      isPdf(url) {
        if (url.match(/.pdf$/)) {
          return true;
        } else {
          return false;
        }
      },
      formatCredits(credits) {
        let displayedCredits;

        if (credits > 1000000) {
          displayedCredits = (credits / 1000000).toFixed(1) + "m";
        } else if (credits > 10000) {
          displayedCredits = Math.floor(credits / 1000) + "k";
        } else {
          displayedCredits = credits;
        }

        return displayedCredits;
      },
      resetResults() {
        this.hasResults = false;
        this.hasImageResults = false;
        this.hasVideoResults = false;

        this.results.places = [];
        this.results.videos = [];
        this.results.stories = [];
        this.results.organic = [];
        this.results.questions = [];
        this.results.searches = [];

        this.isCollapsed.places = true;
        this.isCollapsed.videos = true;
        this.isCollapsed.stories = true;

        this.resultsYoutube.videos = [];
        this.resultsYoutube.similar = [];

        this.hasRestriction = false;
      },
      handleError(error) {
        this.errorCode = error.toUpperCase().replace(/\s+/g, "");
      },
      handleErrorMore(error) {
        this.errorCodeMore = error.toUpperCase().replace(/\s+/g, "");
      },
      clearForm() {
        this.errorCode = null;

        this.query = "";
        this.googleStartParam = 0;

        this.$refs.search_form.focus();
      },
      reloadPage() {
        this.$router.go();
      },
      setBrowserLanguage() {
        const browserLang = navigator.language || navigator.userLanguage;
        const lang = LANGUAGES.find(
          (el) => el.iso === browserLang.substring(0, 2)
        );

        if (lang) {
          this.language = lang;
        } else this.language = LANGUAGES[0];
      },
      onCopy: function (e) {
        this.$bvModal.msgBoxOk(
          "Ein Link zu Trooia mit Ihrem Zugangscode wurde in die Zwischenablage kopiert. Sie können diesen nun in eine Email oder Textnachricht einfügen."
        );
      },
      onCopyError: function (e) {
        this.$bvModal.msgBoxOk(
          "Ihr Zugangscode konnte nicht in die Zwischenablage kopiert werden. Bitte markieren und kopieren Sie den Zugangscode mit der Maus."
        );
      },
      isFriday() {
        const dayofWeek = new Date().getDay();
        return dayofWeek === 5 ? true : false;
      },
    },
  };
</script>

<style scoped>
  #logo {
    width: 250px;
    height: 67px;
  }

  @media screen and (max-width: 420px) {
    #logo {
      width: 180px;
      height: 48px;
    }
  }

  #horse {
    width: 20px;
    height: 20px;
  }

  .languages {
    width: 98px;
    display: inline-block;
  }

  .timeframes {
    width: 110px;
    display: inline-block;
  }

  .red {
    color: #940912;
  }

  .clear-button {
    cursor: pointer;
    margin-top: 14px;
    margin-left: -30px;
    z-index: 100;
  }

  .credits {
    font-size: 0.9em;
  }

  .loadingMessage {
    font-size: 0.8em;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.7s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .clipboard {
    background-color: #940912;
    border-color: #940912;
  }
  .clipboard:hover {
    background-color: #aa0510;
    border-color: #aa0510;
  }

  .place {
    font-size: 1.2em;
    color: #1565c0;
  }
  .place-nolink {
    font-size: 1.2em;
  }

  .place:hover {
    color: #940912;
    cursor: pointer;
    text-decoration: underline;
  }
  .place:visited {
    color: #940912;
  }

  .phone {
    color: #1565c0;
  }
  .phone:hover {
    color: #940912;
    cursor: pointer;
    text-decoration: underline;
  }

  .video {
    font-size: 1.2em;
    color: #1565c0;
  }
  .video:hover {
    color: #940912;
    cursor: pointer;
    text-decoration: underline;
  }
  .video:visited {
    color: #940912;
  }

  .story {
    font-size: 1.2em;
    color: #1565c0;
  }
  .story:hover {
    color: #940912;
    cursor: pointer;
    text-decoration: underline;
  }
  .story:visited {
    color: #940912;
  }

  .organic {
    font-size: 1.2em;
    color: #1565c0;
  }
  .organic:hover {
    color: #940912;
    cursor: pointer;
    text-decoration: underline;
  }
  .organic:visited {
    color: #940912;
  }

  .question {
    font-size: 1.2em;
    color: #1565c0;
  }
  .question:hover {
    color: #940912;
    cursor: pointer;
    text-decoration: underline;
  }
  .question:visited {
    color: #940912;
  }

  .related {
    font-size: 1.2em;
    color: #1565c0;
  }
  .related:hover {
    color: #940912;
    cursor: pointer;
    text-decoration: underline;
  }

  .blinking {
    animation: blinkingText 2s infinite;
  }
  @keyframes blinkingText {
    0% {
      color: #940912;
    }
    80% {
      color: #940912;
    }
    90% {
      color: transparent;
    }
    95% {
      color: transparent;
    }
    100% {
      color: #940912;
    }
  }

  .hyphened {
    hyphens: auto;
  }
</style>
